import { defineStore } from "pinia";
import services from "./services";
import Helper from "@/common/Helper";
import moment from "moment";
import { User } from "./UserStore";
import { Configuracion } from "./CommonStore";
import { ElNotification, ElLoading } from "element-plus";
import { useProductosAcabadoStore } from "./ProductosAcabadoStore";
import { useMaterialesStore } from "./MaterialesStore";
export enum TiposTareas {
  Proyecto = "Proyecto",
  PA = "PA",
  Material = "Material",
}
export enum EstadosTareas {
  NotStarted = "Not started",
  Finished = "Finished",
  OnTime = "On time",
  Delayed = "Delayed",
  ProyectoNoIniciado = "Proyecto no iniciado"
}

export interface Tarea {
  Id: number;
  IdTarea: string;
  IdOrigen: number;
  IdBucket: string;
  IdProyecto: number;
  IdPA: number;
  IdMaterial: number;
  Duracion: number;
  Dependencia: number;
  Fase: string;
  TipoTarea: string;
}
export interface TareaExtend extends Tarea {
  Descripcion: string;
  Departamento: string;
  PersonaClave: string;
  EquipoExtended: string;
  Duracion: number;
}
export interface DataGantt {
  id: number;
  text: string;
  start_date: string;
  end_date: string;
  duration: number;
  progress: number;
  owner_id: string;
  deadline: string;
  planned_start: string;
  planned_end: string;
  parent?:number
  open:boolean
}
export interface LinkGantt {
  id: number;
  source: number;
  target: number;
  type: string;
}
export interface TareasGantt {
  data: DataGantt[];
  links: LinkGantt[];
}
export interface TareaPlanner {
  planId: string;
  bucketId: string;
  title: string;
  orderHint: string;
  assigneePriority: string;
  percentComplete: number;
  startDateTime: string;
  createdDateTime: string;
  dueDateTime: string;
  hasDescription: boolean;
  previewType: string;
  completedDateTime: string;
  referenceCount: number;
  checklistItemCount: number;
  activeChecklistItemCount: number;
  conversationThreadId: string;
  priority: number;
  id: string;
  completedBy: string;
  assignments: any; //{ 'fd3f5c96-20bd-42f1-8cab-278f1202c868': [Object] }
}
export interface TareaConfiguracion {
  Id: number;
  Descripcion: string;
  Duracion: number;
  Dependencia: number;
  Departamento: string;
  Fase: number;
  PersonaClave: string;
  EquipoExtended: string;
  TipoTarea: string;
  CompletarNoCambio: boolean
}
export interface TareasEquiposConfig {
  Key:string;
  Tareas:TareaConfiguracion[]
}
// export interface ReferenciaMateriales extends Referencia {
//     Materiales: Material[]
// }
export const useTareasStore = defineStore("TareaStore", {
  // convert to a function
  state: () => ({
    Tareas: { data: [], links: [] } as TareasGantt,
    TareasPlanner: [] as TareaPlanner[],
    TareasLoaded: false,
    TareasConfiguracion: [] as TareasEquiposConfig[],
  }),
  getters: {
    GetNumberTareasPendientes(state) {
      return state.TareasPlanner.filter(
        (task) => task.completedDateTime === null
      );
    },
    GetNumberTareasDelayed(state) {
      return state.TareasPlanner.filter(
        (task) => {
          const dueDate = moment(task.dueDateTime).endOf("day");
          return dueDate < moment() && task.completedDateTime === null
        });
    },
    GetNumberTareasProximasVencer(state) {
      return state.TareasPlanner.filter(
        (task) => {
          const dueDate = moment(task.dueDateTime).endOf("day");
          const now = moment();
          const nextWeek = moment().add(7, 'days').endOf("day");
          return dueDate >= now && dueDate <= nextWeek && task.completedDateTime === null;
        });
    }
  },
  actions: {
    async GetByIdProyecto(idProyecto: number) {
      //  tareas de bbdd
      const dateKey = new Date().getTime()
      this.TareasLoaded = false;
      const { groupBy } = Helper();
      const ProductosAcabadosStore = useProductosAcabadoStore();
      const MaterialesStore = useMaterialesStore();
      const tareasbbdd =
        await services.TareaService.getByIdProyecto(idProyecto);
      if (tareasbbdd.data && tareasbbdd.data.length > 0) {
        const tareasbbddBuckets = groupBy(tareasbbdd.data, "IdBucket");
        // tareas del planner
        const tareasPlanner = await services.PlannerService.getTareasByBucket(
          Object.keys(tareasbbddBuckets)
        );
        this.TareasPlanner = tareasPlanner.data;

        const idsPAsGroup = groupBy(tareasbbdd.data, "IdPA");
        const idsPAs = Object.keys(idsPAsGroup);
        const idsPasFiter = idsPAs.filter((i) => i !== "0");
        for (let i = 0; i < idsPasFiter.length; i++) {
          const idPa = idsPasFiter[i];
          const pa = ProductosAcabadosStore.ProductosAcabados?.find(
            (pa) => pa.IdPA === Number(idPa)
          );
          if (pa) {
            this.Tareas.data.push({
              id: Number(idPa) + dateKey,
              text: pa?.Descripcion,
              start_date: "",
              end_date: "",
              duration: 0,
              progress: 0,
              owner_id: "",
              deadline: "",
              planned_start: "",
              planned_end: "",
              open: true
            });
            const idsMaterialGrouped = groupBy(
              tareasbbdd.data.filter((t) => t.IdPA === Number(idPa)),
              "IdMaterial"
            );
            const idsMaterial = Object.keys(idsMaterialGrouped);
            await MaterialesStore.GetMaterialesByProductoAcabado(Number(idPa));
            idsMaterial
              .filter((i) => i !== "0")
              .forEach((idMat) => {
                const material = MaterialesStore.MaterialesPA?.find(
                  (m) => m.IdMaterial === Number(idMat)
                );
                if (material) {
                  this.Tareas.data.push({
                    id: material.IdMaterial + dateKey,
                    text: material.Descripcion,
                    start_date: "",
                    end_date: "",
                    duration: 0,
                    progress: 0,
                    owner_id: "",
                    deadline: "",
                    planned_start: "",
                    planned_end: "",
                    open: true,
                    parent: Number(idPa) + dateKey
                  });
                }
              });
          }
        }
        for (let t = 0; t < tareasPlanner.data.length; t++) {
          const taskPlanner: TareaPlanner = tareasPlanner.data[t];

          const taskbbdd = tareasbbdd.data.find(
            (t) => t.IdTarea === taskPlanner.id
          );
          if (taskbbdd) {
            const startDateTime = moment(taskPlanner.startDateTime);
            const dueDateTime = moment(taskPlanner.dueDateTime);
            const startDate = startDateTime.startOf("day");
            const dueDate = dueDateTime.endOf("day");
            const duracion = dueDate.diff(startDate, "days");
            const usersName = [];
            const assignments = Object.keys(taskPlanner.assignments);

            for (let i = 0; i < assignments.length; i++) {
              const user: User = await services.AzureService.getGraphUserById(
                assignments[i]
              );
              if (user) {
                usersName.push(user.displayName);
              }
            }
            let parent = 0
            if (taskbbdd.IdMaterial !== 0) parent = taskbbdd.IdMaterial + dateKey
            else if (taskbbdd.IdPA !== 0) parent = taskbbdd.IdPA + dateKey
            this.Tareas.data.push({
              id: taskbbdd.Id,
              text: taskPlanner.title,
              start_date: startDate.format("YYYY-MM-DDTHH:mm:ssZ"), // taskPlanner.startDateTime,
              end_date:
                taskPlanner.completedDateTime === null
                  ? dueDate.format("YYYY-MM-DDTHH:mm:ssZ")
                  : moment(taskPlanner.completedDateTime)
                      .endOf("day")
                      .add(1, "day")
                      .format("YYYY-MM-DDTHH:mm:ssZ"),
              duration: duracion,
              progress: taskPlanner.percentComplete,
              owner_id: usersName.join(", "),
              deadline: dueDate.format("YYYY-MM-DDTHH:mm:ssZ"),
              planned_start: startDate.format("YYYY-MM-DDTHH:mm:ssZ"),
              planned_end:
                taskPlanner.completedDateTime === null
                  ? dueDate.format("YYYY-MM-DDTHH:mm:ssZ")
                  : moment(taskPlanner.completedDateTime)
                      .endOf("day")
                      .add(1, "day")
                      .format("YYYY-MM-DDTHH:mm:ssZ"),
              parent: parent,
              open: true
            });

            if (taskbbdd.Dependencia !== 0) {
              const tareasPadre = tareasbbdd.data.filter(
                (td) => td.IdOrigen === taskbbdd.Dependencia
              );
              tareasPadre.forEach((tareaPadre) => {
                if (tareaPadre) {
                  if (
                    tareaPadre.TipoTarea === TiposTareas.Proyecto &&
                    taskbbdd.Dependencia === tareaPadre.IdOrigen
                  ) {
                    this.Tareas.links.push({
                      id: taskbbdd.Id,
                      source: tareaPadre.Id,
                      target: taskbbdd.Id,
                      type: "0",
                    });
                  } else if (
                    tareaPadre.TipoTarea === TiposTareas.PA &&
                    taskbbdd.IdPA === tareaPadre.IdPA
                  ) {
                    this.Tareas.links.push({
                      id: taskbbdd.Id,
                      source: tareaPadre.Id,
                      target: taskbbdd.Id,
                      type: "0",
                    });
                  } else if (
                    tareaPadre.TipoTarea === TiposTareas.Material &&
                    taskbbdd.IdMaterial === tareaPadre.IdMaterial
                  ) {
                    this.Tareas.links.push({
                      id: taskbbdd.Id,
                      source: tareaPadre.Id,
                      target: taskbbdd.Id,
                      type: "0",
                    });
                  }
                }
              });
            }
          }
        }
        this.Tareas.data.sort((a, b) => {
          const dateA = new Date(a.start_date).getTime();
          const dateB = new Date(b.start_date).getTime();
          return dateA - dateB;
        });
        
      } else {
        this.EmptyTareas();
        // const ProyectosStore = useProyectosStore()
        // this.Tareas.data.push({
        //   id: dateKey,
        //   text: ProyectosStore.ProyectoSelected.Descripcion,
        //   start_date: "",
        //   end_date: "",
        //   duration: 0,
        //   progress: 0,
        //   owner_id: "",
        //   deadline: "",
        //   planned_start: "",
        //   planned_end: "",
        //   open: true
        // });
      }
      this.TareasLoaded = true;
    },
    async AddTarea(data: Tarea) {
      await services.TareaService.add(data)
        .then(() => {
          console.log("Tarea añadida correctamente");
        })
        .catch((error: Error) => {
          console.error("Error al añadir la fase:", error);
        });
    },
    async UpdateTarea(data: Tarea) {
      await services.TareaService.update(data)
        .then(() => {
          console.log("Tarea update correctamente");
        })
        .catch((error: Error) => {
          console.error("Error al actualizar la fase:", error);
        });
    },
    async DeleteTarea(idTarea: number) {
      await services.TareaService.delete(idTarea)
        .then(() => {
          console.log("Tarea eliminada correctamente");
        })
        .catch((error: Error) => {
          console.error("Error al eliminar la fase:", error);
        });
    },
    EmptyTareas() {
      this.Tareas = { data: [], links: [] };
      this.TareasPlanner = [];
      this.TareasLoaded = false;
    },
    async GetTareasConfiguracion() {
      const ElLoadingInstance = ElLoading.service({ fullscreen: true });
      try {
        const data = (await services.ConfiguracionService.getByKey("Tareas")).data
        this.TareasConfiguracion = JSON.parse(data.Valor);
        ElLoadingInstance.close();
      } catch (error: any) {
        console.log("GetTareasConfiguracion", error);
        ElLoadingInstance.close();
        ElNotification.error({
          showClose: true,
          message: "Se ha producido un error al obtener las tareas.",
        });
      }
    },
    UpdateTareasConfiguracion(data: TareaConfiguracion[], equipoSelected:string, subequipoSelected:string|null) {
      const ElLoadingInstance = ElLoading.service({ fullscreen: true });
      const tareasEditedIndex = this.TareasConfiguracion.findIndex(t => t.Key === equipoSelected || t.Key === subequipoSelected)
      this.TareasConfiguracion[tareasEditedIndex].Tareas = data
      const dataConfig: Configuracion = {
        Key: "Tareas",
        Valor: JSON.stringify(this.TareasConfiguracion),
      };
      services.ConfiguracionService.update(dataConfig)
        .then(() => {
          ElLoadingInstance.close();
          ElNotification.success({
            showClose: true,
            message: "Se ha guardado correctamente las tareas",
          });
        })
        .catch(() => {
          ElLoadingInstance.close();
          ElNotification.error({
            showClose: true,
            message: "Se ha producido un error al guardar las tareas.",
          });
        });
    },
    UpdateTareaConfiguracionStore(tareaActualizada: TareaConfiguracion, equipoSelected:string, subequipoSelected:string|null) {
      // TODO 
      console.log(tareaActualizada)
      const tareasEditedIndex = this.TareasConfiguracion.findIndex(t => t.Key === equipoSelected || t.Key === subequipoSelected)
     if (tareasEditedIndex === -1 ) {
      this.TareasConfiguracion.push({Key: subequipoSelected ? subequipoSelected : equipoSelected, Tareas: [tareaActualizada]})
     } else {
      const indexTarea = this.TareasConfiguracion[tareasEditedIndex].Tareas.findIndex(t => t.Id === tareaActualizada.Id)
      if (indexTarea === -1) {
        this.TareasConfiguracion[tareasEditedIndex].Tareas.push(tareaActualizada)
      } else {
        this.TareasConfiguracion[tareasEditedIndex].Tareas[indexTarea] = tareaActualizada
      }
     }
    },
    CopiarTareasConfiguracionStore(equipoSelectedOrigen:string, subequipoSelectedOrigen:string|null, equipoSelectedDestino:string, subequipoSelectedDestino:string|null) {
    
      const tareasEditedIndex = this.TareasConfiguracion.findIndex(t => t.Key === equipoSelectedOrigen || t.Key === subequipoSelectedOrigen)
     if (tareasEditedIndex === -1 ) {
      ElNotification.error({
        showClose: true,
        message: "Se han encontrado tareas.",
      });
     } else {
      this.TareasConfiguracion.push({
        Key: subequipoSelectedDestino ? subequipoSelectedDestino : equipoSelectedDestino,
        Tareas: this.TareasConfiguracion[tareasEditedIndex].Tareas
      })
    }
  },
  
    RemoveTareaConfiguracionStore(idTarea: number, equipoSelected:string, subequipoSelected:string|null) {
      
      const tareasEditedIndex = this.TareasConfiguracion.findIndex(t => t.Key === equipoSelected || t.Key === subequipoSelected)
     if (tareasEditedIndex > -1 ) {
      {
      const indexTarea = this.TareasConfiguracion[tareasEditedIndex].Tareas.findIndex(t => t.Id === idTarea)
      if (indexTarea > -1) {
        this.TareasConfiguracion[tareasEditedIndex].Tareas.splice(indexTarea, 1)
      }
     }
    }
  },
}
});
