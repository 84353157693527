<template>
  <div id="app">
    <el-container>
      <el-header id="rowLogo" class="main-header" v-show="UserStore.signedIn">
        <el-row :gutter="20">
          <el-col :span="4">
            <div id="divLogo" class="logo">
              <img
                id="imgLogo"
                class="logo"
                src="./assets/IdiliaPIM.png"
                alt="boltic logo"
              />
            </div>
          </el-col>
          <el-col :span="20">
            <div class="login">
              <AutoLogin />
            </div>
          </el-col>
        </el-row>
      </el-header>
      <el-container>
        <el-main class="main-body">
          <router-view />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script setup lang="ts">
import { computed, watch } from "vue";
import { useUserStore } from "./store/UserStore";
import { useCommonStore } from "./store/CommonStore";
import { useFasesStore } from "./store/FaseStore";
import AutoLogin from './components/AutoLogin.vue';
const UserStore = useUserStore();
const CommonStore = useCommonStore();
const FasesStore = useFasesStore();
const signedIn = computed(() => UserStore.signedIn);

watch(signedIn, async (signedIn) => {
  if (signedIn) {
    // Cargar datos
    FasesStore.GetFases();
    CommonStore.GetDatosMaestros();
  }
});


</script>

<style lang="scss">
// $fa-font-path: "~@fortawesome/fontawesome-free/webfonts";
// @import "~@fortawesome/fontawesome-free/scss/fontawesome";
// @import "~@fortawesome/fontawesome-free/scss/solid"; // fas
// @import "~@fortawesome/fontawesome-free/scss/regular"; // far
// @import "~@fortawesome/fontawesome-free/scss/brands"; // fab
@font-face {
  font-family: "Gotham";
  src:
    local("Gotham"),
    url(./fonts/Gotham-Book.otf) format("truetype");
}
#app {
  font-family: "Gotham", Helvetica, Arial, sans-serif;
  font-size: small;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.main-header {
  height: 65px !important;
  margin-bottom: 20px;
  padding-top: 10px !important;
  box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.2);
  background-color: white;
  text-align: left;
}
.main-body {
  padding: 10px !important;
}

@import "./common/style.css";
</style>
./store/ProductosAcabadoStore
