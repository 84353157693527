<template>
  <div>
    <el-page-header @back="goBack">
      <template #content>
        <span class="text-large font-600 mr-3"> OnePage </span>
      </template>
      <template #extra>
        <div class="button-container">
          <el-button
            v-if="!proyectoSeleccionado.Iniciado"
            :loading="loadingIniciarProy"
            class="buttonIdilia"
            round
            @click="iniciarProyecto"
          >
            Iniciar proyecto
          </el-button>
          <el-button class="buttonIdilia" round @click="abrirProyecto">
            Ver detalle
          </el-button>
          <a :href="getUrlPlanner" target="_blank">
            <el-button round class="buttonIdilia">Ver tareas</el-button>
          </a>
        </div>
      </template>
    </el-page-header>
    <el-container>
      <el-main>
        <el-row :gutter="30" style="margin-bottom: 0px">
          <el-col :span="4">
            <div class="image__preview demo-image__error">
              <el-image
                style="max-width: 200px; height: 100px; margin-left: 40px"
                :src="proyectoSeleccionado.Foto"
                :preview-src-list="[proyectoSeleccionado.Foto]"
                :initial-index="0"
                fit="fill"
              >
                <template #error>
                  <div class="image-slot">
                    <el-icon><icon-picture /></el-icon>
                  </div>
                </template>
              </el-image>
            </div>
          </el-col>
          <el-col :span="5">
            <el-row :gutter="30">
              <el-col>{{ proyectoSeleccionado.Descripcion }}</el-col>
            </el-row>
            <el-row>
              <el-col>
                Líderes:
                <div
                  v-if="
                    !proyectoSeleccionado.Lideres ||
                    proyectoSeleccionado.Lideres.trim().length === 0
                  "
                >
                  No se han seleccionado líderes.
                </div>
                <mgt-person
                  v-for="p in proyectoSeleccionado.Lideres?.split(';')"
                  v-else
                  :key="p"
                  :user-id="p"
                  view="oneline"
                  person-card="hover"
                  fetch-image
                />
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="15">
            <el-row>
              <el-col :span="24">
                <el-row>
                  <el-col :span="4">
                    <el-statistic
                      title="Tiempo transcurrido"
                      :value="DaysSince(proyectoSeleccionado.Creado) + ' días'"
                    />
                  </el-col>
                  <el-col :span="1">
                    <el-divider direction="vertical" class="divider-large" />
                  </el-col>
                  <el-col :span="4">
                    <el-statistic
                      title="Tareas pendientes"
                      :value="TareasStore.GetNumberTareasPendientes.length"
                    />
                  </el-col>
                  <el-col :span="1">
                    <el-divider direction="vertical" class="divider-large" />
                  </el-col>
                  <el-col :span="4">
                    <el-statistic
                      title="Tareas fuera de plazo"
                      :value="TareasStore.GetNumberTareasDelayed.length"
                    />
                  </el-col>
                  <el-col :span="1">
                    <el-divider direction="vertical" class="divider-large" />
                  </el-col>
                  <el-col :span="4">
                    <el-statistic
                      title="Vencen los próximos 7 días"
                      :value="TareasStore.GetNumberTareasProximasVencer.length"
                    />
                  </el-col>
                  <el-col :span="1">
                    <el-divider direction="vertical" class="divider-large" />
                  </el-col>
                  
                 
                  <el-col :span="4">
                    <el-statistic
                      title="Deadline Liberación PA"
                      :value="dateToString(proyectoSeleccionado.FechaServicio)"
                    />
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row :gutter="30">
          <el-col :span="8">
            Referencias:
            <el-table
              ref="tableReferenciasPA"
              :data="productosAcabados"
              style="width: 100%; max-height: 500px; box-sizing: border-box"
              empty-text="No se han seleccionado referencias"
              stripe
              table-layout="fixed"
              @row-click="openPanelMateriales"
            >
              <el-table-column label="Código" width="90" property="Codigo" />
              <el-table-column
                property="Descripcion"
                label="Descripcion"
                show-overflow-tooltip
              />
              <el-table-column width="50" show-overflow-tooltip>
                <template #default="scope">
                  <span
                    v-if="scope.row.EditarCodigo"
                    class="dotWithOutTooltip"
                    :style="'background-color: #E00D0D !important;text-align: center;margin-right: 10px; color: transparent !important;'"
                  >
                    Nueva referencia
                  </span>
                </template>
              </el-table-column>
            </el-table>
            <el-drawer
              v-model="panelVisible"
              :title="referenciaSelected?.Descripcion"
              direction="rtl"
              size="50%"
            >
              <div class="image__preview demo-image__error">
                <el-image
                  style="max-width: 200px; height: 100px; margin-left: 40px"
                  :src="referenciaSelected?.Foto"
                  fit="fill"
                >
                  <template #error>
                    <div class="image-slot">
                      <el-icon><icon-picture /></el-icon>
                    </div>
                  </template>
                </el-image>
              </div>
              <el-descriptions
                title="Datos de la referencia"
                border
                :column="1"
              >
                <el-descriptions-item label="Marca">
                  {{ referenciaSelected?.Marca }}
                </el-descriptions-item>
                <el-descriptions-item label="Familia">
                  {{ familia?.PROD_HIER + " " + familia?.TEXT }}
                </el-descriptions-item>
                <el-descriptions-item label="Subfamilia">
                  {{ subfamilia?.PROD_HIER + " " + subfamilia?.TEXT }}
                </el-descriptions-item>
                <el-descriptions-item label="Formato">
                  {{ formato?.PROD_HIER + " " + formato?.TEXT }}
                </el-descriptions-item>
              </el-descriptions>
              <span class="title">Materiales</span>
              <el-table :data="Materiales">
                <el-table-column property="Codigo" label="Código" width="150" />
                <el-table-column property="Descripcion" label="Descripcion" />
                <el-table-column width="50" show-overflow-tooltip>
                  <template #default="scope">
                    <span
                      v-if="scope.row.CambioDiseno"
                      class="dotWithOutTooltip"
                      :style="
                        'background-color: #E00D0D' +
                        ' !important;text-align: center;margin-right: 10px;' +
                        'color: transparent !important;'
                      "
                      >Cambio diseño</span
                    >
                  </template>
                </el-table-column>
                <el-table-column property="Tipo" width="100" label="Tipo" />
                <el-table-column
                  property="Cantidad"
                  width="100"
                  label="Cantidad"
                />
                <el-table-column property="UM" width="100" label="UM" />
              </el-table>
            </el-drawer>
          </el-col>
          <el-col v-loading="loading" :span="16">
            <div style="display: flex; justify-content: flex-end">
              <el-button
                :icon="Refresh"
                circle
                class="icon-idilia"
                style="margin: 10px; float: right"
                @click="RefreshTareas"
              />
            </div>
            <GanttTasks class="left-container" />
          </el-col>
        </el-row>
      </el-main>
    </el-container>
  </div>
</template>
<script setup lang="ts">
import {
  ref,
  reactive,
  computed,
  defineAsyncComponent,
  onBeforeUnmount,
} from "vue";
import { Picture as IconPicture, Refresh } from "@element-plus/icons-vue";
import { useRouter, useRoute } from "vue-router";
import { useProyectosStore, Proyecto } from "@/store/ProyectosStore";
import { registerMgtPersonComponent } from "@microsoft/mgt";
import {
  ProductoAcabado,
  useProductosAcabadoStore,
} from "@/store/ProductosAcabadoStore";
import { useCommonStore } from "@/store/CommonStore";
import { Material, useMaterialesStore } from "@/store/MaterialesStore";
import { EstadosTareas, useTareasStore } from "@/store/TareasStore";
import { onMounted } from "vue";
import Helper from "@/common/Helper";
import { ElMessageBox, ElNotification, UploadUserFile } from "element-plus";
import { FaseEstado, useFasesStore } from "@/store/FaseStore";
const FaseStore = useFasesStore();
const { DaysSince, dateToString, keyExistsEquipos } = Helper();
const GanttTasks = defineAsyncComponent(
  () => import("../components/GanttTasks.vue")
);
registerMgtPersonComponent();
const router = useRouter();
const route = useRoute();
const TareasStore = useTareasStore();
const ProyectosStore = useProyectosStore();
const ProductosAcabadoStore = useProductosAcabadoStore();
const MaterialesStore = useMaterialesStore();
const CommonStore = useCommonStore();
// const proyectoFoto = ref<UploadUserFile>({ name: '', url: ''})
const referenciaSelected = ref<ProductoAcabado>();
const Materiales = ref<Material[]>();
const panelVisible = ref(false);
const proyectoEmpty: Proyecto = {
  IdProyecto: 0,
  Descripcion: "",
  FechaServicio: "",
  Lideres: "",
  Foto: "",
  LoteMixto: false,
  LoteMixtoComponentes: "",
  GruposAcceso: "",
  EstimacionComercial: 0,
  Clientes: "",
  Fase: "",
  Creado: "",
  CreadoPor: "",
  Equipo: "",
  Completado: false,
  Duracion: 0,
  Iniciado: false,
  KeyEquipo: "",
  TareasProximasVencer: 0,
  TareasVencidas: 0
};
const loadingIniciarProy = ref(false);
let proyectoSeleccionado: Proyecto = reactive<Proyecto>(proyectoEmpty);
const goBack = () => {
  router.push({ name: "Dashboard" });
};
// const proySel = ProyectosStore.Proyectos?.find((data:Proyecto) => data.IdProyecto.toString() === route.params.Pid)
proyectoSeleccionado = ProyectosStore.ProyectoSelected || proyectoEmpty;
const productosAcabados = computed(() => {
  return ProductosAcabadoStore.ProductosAcabados;
});
const loading = computed(() => {
  return !TareasStore.TareasLoaded;
});
const getUrlPlanner = computed(() => {
  return (
    (process.env.VUE_APP_URLPLANNER as string) + proyectoSeleccionado.IdPlanner
  );
});
const openPanelMateriales = async (val: ProductoAcabado | undefined) => {
  if (val) {
    await MaterialesStore.GetMaterialesByProductoAcabado(val.IdPA);
  }
  referenciaSelected.value = val;
  panelVisible.value = true;
  Materiales.value = MaterialesStore.MaterialesPA;
};
const iniciarProyecto = async () => {
  loadingIniciarProy.value = true;
  if (proyectoSeleccionado.KeyEquipo && keyExistsEquipos(proyectoSeleccionado.KeyEquipo)) {
    if (productosAcabados.value && productosAcabados.value.length > 0) {
    for (let i = 0; i < productosAcabados.value.length; i++) {
      const pa = productosAcabados.value[i];
      await CommonStore.UpdatePlannerTasks(proyectoSeleccionado, pa, null);
      await MaterialesStore.GetMaterialesByProductoAcabado(pa.IdPA);
      if (
        MaterialesStore.MaterialesPA &&
        MaterialesStore.MaterialesPA.length > 0
      ) {
        for (let m = 0; m < MaterialesStore.MaterialesPA.length; m++) {
          const material = MaterialesStore.MaterialesPA[m];
          if (material.CambioDiseno) {
            await CommonStore.UpdatePlannerTasks(
              proyectoSeleccionado,
              pa,
              material
            );
          }
        }
      }
    }
  } else {
    await CommonStore.UpdatePlannerTasks(proyectoSeleccionado, null, null);
  }
  proyectoSeleccionado.Iniciado = true;
  const faseEstados: FaseEstado[] = [];
  if (FaseStore.Fases) {
    FaseStore.Fases.forEach((fase) => {
      faseEstados.push({ Fase: fase.Id, Estado: EstadosTareas.NotStarted });
    });
    proyectoSeleccionado.Fase = JSON.stringify(faseEstados);
  }
  const proyectoFoto: UploadUserFile = {
    name: "",
    url: proyectoSeleccionado.Foto,
  };
  ProyectosStore.UpdateProyecto(proyectoSeleccionado, proyectoFoto);
  ElNotification({
    title: "Proyecto iniciado",
    message:
      "El proyecto se ha iniciado correctamente. Es posible que necesite actualizar para ver todas las tareas correctamente.",
    type: "success",
    offset: 100,
  });
  } else {
    ElMessageBox.alert('No se ha encontrado ningún equipo para este proyecto. Es posible que el equipo ya no exista o no tenga tareas configuradas. Proyecto no iniciado.',
    'Atención', {
    confirmButtonText: 'OK',
  })
  }
  
  loadingIniciarProy.value = false;
};
const abrirProyecto = () => {
  MaterialesStore.EmptyMaterialesPA();
  router.push({ name: "Wizard", params: { Pid: route.params.Pid } });
};

const familia = computed(() => {
  return CommonStore.Familias?.filter(
    (item) => item.PROD_HIER === referenciaSelected.value?.Familia
  )[0];
});

const subfamilia = computed(() => {
  return CommonStore.Subfamilias?.filter(
    (item) => item.PROD_HIER === referenciaSelected.value?.Subfamilia
  )[0];
});

const formato = computed(() => {
  return CommonStore.Formatos?.filter(
    (item) => item.PROD_HIER === referenciaSelected.value?.FormatoComercial
  )[0];
});
const RefreshTareas = async () => {
  TareasStore.EmptyTareas();
  await ProductosAcabadoStore.GetProductoAcabadoById(Number(route.params.Pid));
  await TareasStore.GetByIdProyecto(Number(route.params.Pid));
};
onMounted(async () => {
  // await ProyectosStore.GetProyectoById(Number(route.params.Pid))
  await ProductosAcabadoStore.GetProductoAcabadoById(Number(route.params.Pid));
  await TareasStore.GetByIdProyecto(Number(route.params.Pid));
  if (!proyectoSeleccionado.Iniciado)
    await TareasStore.GetTareasConfiguracion()
});
onBeforeUnmount(async () => {
  TareasStore.EmptyTareas();
});
</script>
<style scoped>
/* html, body {
    height: 100%;
    margin: 0;
    padding: 0;
  } */
/* .container {
    height: 100vh;
    width: 100%;
  } */

.left-container {
  overflow: hidden;
  position: relative;
  height: 65vh;
}
</style>
