import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, vShow as _vShow, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = { class: "wizard-footer-left" }
const _hoisted_3 = { class: "wizard-footer-right" }

import { ref, reactive, defineAsyncComponent, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { ElMessageBox } from "element-plus";
import { Proyecto, useProyectosStore } from "@/store/ProyectosStore";
import { FormWizard, TabContent } from "vue3-form-wizard";
import "vue3-form-wizard/dist/style.css";
import type { UploadUserFile } from "element-plus";
import {
  ProductoAcabado,
  useProductosAcabadoStore,
} from "@/store/ProductosAcabadoStore";
import { Roles } from "@/store/CommonStore";
// import { useMaterialesStore } from '@/store/MaterialesStore'
import { useUserStore } from "@/store/UserStore";
import {
  ValidateData,
  ValidateDataItem,
  useCommonStore,
} from "@/store/CommonStore";
import { useTareasStore } from "@/store/TareasStore";
import { useMaterialesStore } from "@/store/MaterialesStore";
import { useClientesStore } from "@/store/ClientesStore";

export default /*@__PURE__*/_defineComponent({
  __name: 'WizardView',
  setup(__props) {

const FormularioProyecto = defineAsyncComponent(
  () => import("../components/FormularioProyecto.vue")
);
const FormularioSeleccionPA = defineAsyncComponent(
  () => import("../components/FormularioSeleccionPA.vue")
);
const FormularioSeleccionMateriales = defineAsyncComponent(
  () => import("../components/FormularioSeleccionMateriales.vue")
);
const fullscreenLoading = ref(false);
const route = useRoute();
const validateDataProyecto = ref<ValidateData>({
  Descripcion: false,
  FechaServicio: false,
  Equipo: false,
  Subnivel: false
});
const validateDataEmpty = {
  Descripcion: false,
  ForoAprobacionPA: false,
  Marca: false,
  Familia: false,
  Subfamilia: false,
  FormatoComercial: false,
  UnidadMedidaBasicaUMB: false,
  TipoProducto: false,
  TipoVenta: false,
  CanalVenta: false,
};
const validateDataProdAcabado = ref<ValidateDataItem[]>([
  { idPA: 0, ValidateData: validateDataEmpty },
]);
const ProyectosStore = useProyectosStore();
const TareasStore = useTareasStore();
const MaterialesStore = useMaterialesStore();
// const MaterialesStore = useMaterialesStore()
const ProductosAcabadosStore = useProductosAcabadoStore();
const ClientesStore = useClientesStore();
const UserStore = useUserStore();
const CommonStore = useCommonStore();
const proyectoFoto = ref<UploadUserFile>({ name: "", url: "" });
const editarProyecto = ref(route.params.Pid === "0");
const proyectoEmpty: Proyecto = {
  IdProyecto: 0,
  Descripcion: "",
  FechaServicio: "",
  Lideres: "",
  Foto: "",
  LoteMixto: false,
  LoteMixtoComponentes: "",
  GruposAcceso: "",
  EstimacionComercial: 0,
  Clientes: "",
  Fase: "",
  Creado: "",
  CreadoPor: "",
  Equipo: "",
  Completado: false,
  Duracion: 0,
  Iniciado: false,
  KeyEquipo: "",
  TareasProximasVencer: 0,
  TareasVencidas: 0
};

let productosAcabados: ProductoAcabado[] = reactive([]);
const formProyecto: Proyecto = reactive<Proyecto>(
  route.params.Pid === "0" ? proyectoEmpty : ProyectosStore.ProyectoSelected
);
const visibleFormularioProductoAcabado = ref(false);
const visibleFormularioMateriales = ref(false);
const activeTabIndexWizard = ref(0);
const router = useRouter();
const goBack = () => {
  ProyectosStore.GetProyectos();
  ProductosAcabadosStore.GetAllProductoAcabadoEnCurso();
  router.go(-1);
};

const onComplete = async () => {
  ProyectosStore.GetProyectos();
  ProductosAcabadosStore.GetAllProductoAcabadoEnCurso();
  TareasStore.EmptyTareas();
  MaterialesStore.EmptyMaterialesPA();
  await ProyectosStore.GetProyectoById(formProyecto.IdProyecto);
  router.push({ name: "OnePage", params: { Pid: formProyecto.IdProyecto } });
};
const EditarProyectoHandle = () => {
  editarProyecto.value = true
  validateDataProyecto.value = {
    Descripcion: true,
    FechaServicio: true,
    Equipo: true,
    Subnivel: true
  }
}
const checkIsValid = (data: ValidateData, form: Proyecto | ProductoAcabado) => {
  let result = true;
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key) &&
      Object.prototype.hasOwnProperty.call(data, 'Equipo') // verificando si tiene la key equipo, se sabe que es un proyecto, asi solo se valida la descripcion del proyecto no de la PA
      && key === 'Descripcion') {
        if (ProyectosStore.Proyectos && ProyectosStore.Proyectos.some( p=> p.Descripcion.trim().toLocaleLowerCase() === form[key].trim().toLocaleLowerCase())) {
          result = false
        }
    }
    if (
      !(
        Object.prototype.hasOwnProperty.call(data, key) &&
        (data[key] || form[key])
      )
    ) {
      result = false;
    }
  }
  return result;
};
const checkDocsAttached = (form: ProductoAcabado) => {
  if (form.ForoAprobacionPA && form.ForoAprobacionPA?.indexOf("CEXT") > -1) {
    return form.Documentos && form.Documentos.length > 0;
  }
  return true;
};
const addProyectoAsync = async (
  formProyecto: Proyecto,
  image: UploadUserFile
) => {
  try {
    const result = await ProyectosStore.AddProyecto(formProyecto, image);
    if (result) {
      formProyecto.IdProyecto = result.data.IdProyecto;
      if (productosAcabados.length > 0) {
        for (
          let i = 0;
          i < productosAcabados.filter((pa) => !pa.Deleted).length;
          i++
        ) {
          productosAcabados[i].IdProyecto = result.data.IdProyecto;
          productosAcabados[i].Codigo = productosAcabados[i].EditarCodigo
            ? ""
            : productosAcabados[i].ReferenciaModelo;
          await ProductosAcabadosStore.AddProductoAcabado(productosAcabados[i]);
        }

        if (ProductosAcabadosStore.ProductosAcabados)
          productosAcabados = ProductosAcabadosStore.ProductosAcabados;

        validateDataProdAcabado.value = [];
        productosAcabados.forEach((pa) => {
          ConfigureValidateData(pa);
        });
      }
      CommonStore.CreatePlanner(result.data);
    } else {
      console.log("No se pudo añadir el proyecto");
      // resolve(false);
    }
  } catch (error: unknown) {
    console.error("Error al manejar el proyecto:", error);
    // reject(error);
  }
};

const updateProyectoAsync = async (
  formProyecto: Proyecto,
  image: UploadUserFile
) => {
  try {
    await ProyectosStore.UpdateProyecto(formProyecto, image);
    for (let i = 0; i < productosAcabados.length; i++) {
      if (productosAcabados[i].Deleted === true) {
        if (productosAcabados[i].IdPA > 0) {
          await ProductosAcabadosStore.DeleteProductoAcabado(
            productosAcabados[i].IdPA
          );
        }
        ProductosAcabadosStore.DeleteProductoAcabadoStore(productosAcabados[i]);
      } else if (productosAcabados[i].IdPA === 0) {
        productosAcabados[i].IdProyecto = formProyecto.IdProyecto;
        productosAcabados[i].Codigo = productosAcabados[i].EditarCodigo
          ? ""
          : productosAcabados[i].ReferenciaModelo;
        await ProductosAcabadosStore.AddProductoAcabado(productosAcabados[i]);
      } else {
        await ProductosAcabadosStore.UpdateProductoAcabado(
          productosAcabados[i]
        );
      }
      if (!productosAcabados[i].Deleted && formProyecto.Iniciado) {
        CommonStore.UpdatePlannerTasks(
          formProyecto,
          productosAcabados[i],
          null
        );
      } else {
        CommonStore.DeletePlannerTasks(productosAcabados[i].IdPA, "PA");
      }
    }

    if (ProductosAcabadosStore.ProductosAcabados)
      productosAcabados = ProductosAcabadosStore.ProductosAcabados;
    validateDataProdAcabado.value = [];
    productosAcabados.forEach((pa) => {
      ConfigureValidateData(pa);
    });
  } catch (error: unknown) {
    console.error("Error al manejar el proyecto:", error);
    // reject(error);
  }
};

const SaveActiveTab = () => {
  switch (activeTabIndexWizard.value) {
    case 0:
      validateAsyncProyecto(true);
      break;
    case 1:
      validateAsyncReferencias(true);
      break;
    case 2:
      onComplete();
      break;
    default:
      break;
  }
};
const validateAsyncProyecto = (changeEditProyectoButton: boolean = false) => {
  fullscreenLoading.value = true;
  return new Promise((resolve, reject) => {
    const executeAsync = async () => {
      try {
        if (!editarProyecto.value) {
          visibleFormularioProductoAcabado.value = true;
          visibleFormularioMateriales.value = false;
          fullscreenLoading.value = false;
          resolve(true);
        } else {
          if (checkIsValid(validateDataProyecto.value, formProyecto)) {
            formProyecto.Foto = proyectoFoto.value.url;
            formProyecto.FechaServicio = new Date(
              formProyecto.FechaServicio
            ).toISOString();

            if (formProyecto.IdProyecto === 0) {
              formProyecto.Creado = new Date().toISOString();
              formProyecto.CreadoPor = UserStore.CurrentUser.id;
              await addProyectoAsync(formProyecto, proyectoFoto.value);
            } else {
              await updateProyectoAsync(formProyecto, proyectoFoto.value);
              // ProyectosStore.UpdateProyecto(formProyecto, proyectoFoto.value);
            }
            if (changeEditProyectoButton) editarProyecto.value = false;
            visibleFormularioProductoAcabado.value = true;
            visibleFormularioMateriales.value = false;
            fullscreenLoading.value = false;
            resolve(true);
          } else {
            fullscreenLoading.value = false;
            await ElMessageBox.alert(
              "El formulario tiene campos requeridos que no se han informado o no son correctos. Por favor, corríjelos.",
              "Atención",
              {
                confirmButtonText: "OK",
              }
            );

            reject(
              "El formulario tiene campos requeridos que no se han informado o no son correctos. Por favor, corríjelos."
            );
          }
        }
      } catch (error: unknown) {
        console.error(error);
        fullscreenLoading.value = false;
        reject(error);
      }
    };

    executeAsync();
  });
};

const validateAsyncReferencias = (
  changeEditProyectoButton: boolean = false
) => {
  fullscreenLoading.value = true;
  return new Promise((resolve, reject) => {
    const executeAsync = async () => {
      try {
        if (editarProyecto.value) {
          await ProyectosStore.UpdateProyecto(formProyecto, proyectoFoto.value);
          const paError: string[] = [];
          if (productosAcabados.length > 0) {
          productosAcabados.forEach((pa) => {
            const validateData = validateDataProdAcabado.value.find(
              (v) => v.idPA === pa.IdPA
            );
            if (validateData && !checkIsValid(validateData.ValidateData, pa)) {
              const fieldsError =
                "<li>" +
                Object.keys(validateData.ValidateData)
                  .filter((key) => !validateData.ValidateData[key])
                  .join("</li><li>") +
                "</li>";
              paError.push(`${pa.Codigo} - ${pa.Descripcion} tiene los siguientes campos no válidos o requeridos sin rellenar: <br>
              <ul>${fieldsError}</ul><br>`);
            }
            if (!checkDocsAttached(pa)) {
              paError.push(
                `${pa.Codigo} - ${pa.Descripcion} ha de tener documentos adjuntos.`
              );
            }
          });
        } else {
          paError.push(
                `No existe ninguna PA`
              )
        }
          if (paError.length === 0) {
            productosAcabados.forEach(async (prodAcabado) => {
              if (prodAcabado.IdPA > 0) {
                await ProductosAcabadosStore.UpdateProductoAcabado(prodAcabado);
                if (formProyecto.Iniciado) {
                  CommonStore.UpdatePlannerTasks(
                    formProyecto,
                    prodAcabado,
                    null
                  );
      } 
              }
            });
            ProductosAcabadosStore.GetProductoAcabadoById(
              formProyecto.IdProyecto
            );
            if (changeEditProyectoButton) editarProyecto.value = false;
            visibleFormularioProductoAcabado.value = false;
            visibleFormularioMateriales.value = true;
            fullscreenLoading.value = false;
            resolve(true);
          } else {
            fullscreenLoading.value = false;
            await ElMessageBox.alert(paError.join("<br>"), "Atención", {
              confirmButtonText: "OK",
              dangerouslyUseHTMLString: true,
              type: "warning",
            });
            reject(
              "El formulario tiene campos requeridos que no se han informado o no son correctos. Por favor, corríjelos."
            );
          }
        } else {
          ProductosAcabadosStore.GetProductoAcabadoById(
            formProyecto.IdProyecto
          );
          visibleFormularioProductoAcabado.value = false;
          visibleFormularioMateriales.value = true;
          fullscreenLoading.value = false;
          resolve(true);
        }
      } catch (error: unknown) {
        console.error(error);
        fullscreenLoading.value = false;
        reject(error);
      }
    };
    executeAsync();
  });
};
const setValidateDataProyecto = (field: string, value: boolean) => {
  validateDataProyecto.value[field] = value;
};
const setvalidateDataProdAcabado = (
  field: string,
  value: boolean,
  idPA: number
) => {
  const index = validateDataProdAcabado.value.findIndex((v) => v.idPA === idPA);
  if (index && index > -1) {
    validateDataProdAcabado.value[index].ValidateData[field] = value;
  }
};
const updateFotoProyecto = (foto: UploadUserFile) => {
  proyectoFoto.value = foto;
};
const updateProductosAcabados = (prodAcabados: ProductoAcabado[]) => {
  productosAcabados = prodAcabados;
};
onMounted(async () => {
  if (route.params.Pid != "0") {
    await ProductosAcabadosStore.GetProductoAcabadoById(
      parseInt(route.params.Pid.toString())
    );
    validateDataProdAcabado.value = [];
    ProductosAcabadosStore.ProductosAcabados?.forEach((referencia) => {
      productosAcabados.push({ ...referencia });
      ConfigureValidateData(referencia);
    });
    proyectoFoto.value = { name: "", url: formProyecto.Foto };
  } else {
    productosAcabados = [];
    ProductosAcabadosStore.EmptyProductosAcabados();
  }
  await ClientesStore.GetClientes();
});

const ConfigureValidateData = (pa: ProductoAcabado) => {
  const validate: ValidateData = { ...validateDataEmpty };

  Object.keys(validate).forEach((v) => {
    if (pa[v]) {
      validate[v] = true;
    }
  });

  validateDataProdAcabado.value.push({ idPA: pa.IdPA, ValidateData: validate });
};

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_page_header = _resolveComponent("el-page-header")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_footer = _resolveComponent("el-footer")!
  const _component_el_container = _resolveComponent("el-container")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_page_header, { onBack: goBack }, {
      content: _withCtx(() => _cache[0] || (_cache[0] = [
        _createElementVNode("span", { class: "text-large font-600 mr-3" }, " Wizard ", -1)
      ])),
      extra: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _withDirectives(_createVNode(_component_el_button, {
            round: "",
            class: "buttonIdilia",
            onClick: EditarProyectoHandle
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode(" Editar proyecto ")
            ])),
            _: 1
          }, 512), [
            [_vShow, 
              !editarProyecto.value &&
              _unref(route).params.Pid !== '0' &&
              (!formProyecto.Completado ||
                _unref(UserStore).userRoles.includes(_unref(Roles).Admin))
            ]
          ]),
          _withDirectives(_createVNode(_component_el_button, {
            round: "",
            class: "buttonIdilia",
            onClick: SaveActiveTab
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode(" Guardar ")
            ])),
            _: 1
          }, 512), [
            [_vShow, editarProyecto.value && _unref(route).params.Pid !== '0']
          ])
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_el_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_main, null, {
          default: _withCtx(() => [
            _createVNode(_unref(FormWizard), {
              color: "#E00D0D",
              style: {"border-radius":"20px !important"}
            }, {
              footer: _withCtx((props) => [
                _createElementVNode("div", _hoisted_2, [
                  (props.activeTabIndex > 0)
                    ? (_openBlock(), _createBlock(_component_el_button, {
                        key: 0,
                        style: _normalizeStyle(props.fillButtonStyle),
                        round: "",
                        class: "buttonIdilia",
                        onClick: ($event: any) => {
                  props.prevTab();
                  activeTabIndexWizard.value = props.activeTabIndex - 1;
                }
                      }, {
                        default: _withCtx(() => _cache[3] || (_cache[3] = [
                          _createTextVNode(" Atras ")
                        ])),
                        _: 2
                      }, 1032, ["style", "onClick"]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_3, [
                  (!props.isLastStep)
                    ? _withDirectives((_openBlock(), _createBlock(_component_el_button, {
                        key: 0,
                        round: "",
                        class: "buttonIdilia",
                        style: _normalizeStyle(props.fillButtonStyle),
                        onClick: ($event: any) => {
                  props.nextTab();
                  activeTabIndexWizard.value = props.activeTabIndex + 1;
                }
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(editarProyecto.value ? "Guardar & Siguiente" : "Siguiente"), 1)
                        ]),
                        _: 2
                      }, 1032, ["style", "onClick"])), [
                        [
                          _directive_loading,
                          fullscreenLoading.value,
                          void 0,
                          {
                            fullscreen: true,
                            lock: true
                          }
                        ]
                      ])
                    : (_openBlock(), _createBlock(_component_el_button, {
                        key: 1,
                        round: "",
                        class: "buttonIdilia",
                        style: _normalizeStyle(props.fillButtonStyle),
                        onClick: onComplete
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(props.isLastStep ? "Finalizar" : "Next"), 1)
                        ]),
                        _: 2
                      }, 1032, ["style"]))
                ])
              ]),
              default: _withCtx(() => [
                _createVNode(_unref(TabContent), {
                  title: "Proyecto",
                  "before-change": validateAsyncProyecto
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(FormularioProyecto), {
                      "form-proyecto": formProyecto,
                      "editar-proyecto": editarProyecto.value,
                      "productos-acabados": _unref(productosAcabados),
                      "proyecto-foto": proyectoFoto.value,
                      "validate-data-proyecto": validateDataProyecto.value,
                      onValidateDataProyecto: setValidateDataProyecto,
                      onUpdateFotoProyecto: updateFotoProyecto,
                      onUpdateProductosAcabados: updateProductosAcabados
                    }, null, 8, ["form-proyecto", "editar-proyecto", "productos-acabados", "proyecto-foto", "validate-data-proyecto"])
                  ]),
                  _: 1
                }),
                _createVNode(_unref(TabContent), {
                  title: "Productos acabados",
                  "before-change": validateAsyncReferencias
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(FormularioSeleccionPA), {
                      visible: visibleFormularioProductoAcabado.value,
                      "form-proyecto": formProyecto,
                      "productos-acabados": _unref(productosAcabados),
                      "editar-proyecto": editarProyecto.value,
                      onValidateDataProdAcabado: setvalidateDataProdAcabado,
                      onUpdateProductosAcabados: updateProductosAcabados
                    }, null, 8, ["visible", "form-proyecto", "productos-acabados", "editar-proyecto"])
                  ]),
                  _: 1
                }),
                _createVNode(_unref(TabContent), { title: "Materiales" }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(FormularioSeleccionMateriales), {
                      visible: visibleFormularioMateriales.value,
                      "productos-acabados": _unref(productosAcabados),
                      "form-proyecto": formProyecto,
                      "editar-proyecto": editarProyecto.value
                    }, null, 8, ["visible", "productos-acabados", "form-proyecto", "editar-proyecto"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_footer)
      ]),
      _: 1
    })
  ]))
}
}

})