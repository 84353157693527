<template>
  <div>
    <el-page-header @back="goBack">
      <template #content>
        <span class="text-large font-600 mr-3"> Wizard </span>
      </template>
      <template #extra>
        <div class="flex items-center">
          <el-button
            v-show="
              !editarProyecto &&
              route.params.Pid !== '0' &&
              (!formProyecto.Completado ||
                UserStore.userRoles.includes(Roles.Admin))
            "
            round
            class="buttonIdilia"
            @click="EditarProyectoHandle"
          >
            Editar proyecto
          </el-button>
          <el-button
            v-show="editarProyecto && route.params.Pid !== '0'"
            round
            class="buttonIdilia"
            @click="SaveActiveTab"
          >
            Guardar
          </el-button>
        </div>
      </template>
    </el-page-header>

    <el-container>
      <el-main>
        <!-- back-button-text="Atras"
          :next-button-text="editarProyecto?'Guardar & Siguiente' : 'Siguiente'"
          finish-button-text="Finalizar" 
        @on-complete="onComplete"-->
        <form-wizard color="#E00D0D" style="border-radius: 20px !important">
          <tab-content title="Proyecto" :before-change="validateAsyncProyecto">
            <FormularioProyecto
              :form-proyecto="formProyecto"
              :editar-proyecto="editarProyecto"
              :productos-acabados="productosAcabados"
              :proyecto-foto="proyectoFoto"
              :validate-data-proyecto="validateDataProyecto"
              @validate-data-proyecto="setValidateDataProyecto"
              @update-foto-proyecto="updateFotoProyecto"
              @update-productos-acabados="updateProductosAcabados"
            />
          </tab-content>
          <tab-content
            title="Productos acabados"
            :before-change="validateAsyncReferencias"
          >
            <FormularioSeleccionPA
              :visible="visibleFormularioProductoAcabado"
              :form-proyecto="formProyecto"
              :productos-acabados="productosAcabados"
              :editar-proyecto="editarProyecto"
              @validate-data-prod-acabado="setvalidateDataProdAcabado"
              @update-productos-acabados="updateProductosAcabados"
            />
          </tab-content>
          <tab-content title="Materiales">
            <FormularioSeleccionMateriales
              :visible="visibleFormularioMateriales"
              :productos-acabados="productosAcabados"
              :form-proyecto="formProyecto"
              :editar-proyecto="editarProyecto"
            />
          </tab-content>
          <template #footer="props">
            <div class="wizard-footer-left">
              <el-button
                v-if="props.activeTabIndex > 0"
                :style="props.fillButtonStyle"
                round
                class="buttonIdilia"
                @click="
                  props.prevTab();
                  activeTabIndexWizard = props.activeTabIndex - 1;
                "
              >
                Atras
              </el-button>
            </div>
            <div class="wizard-footer-right">
              <el-button
                v-if="!props.isLastStep"
                v-loading.fullscreen.lock="fullscreenLoading"
                round
                class="buttonIdilia"
                :style="props.fillButtonStyle"
                @click="
                  props.nextTab();
                  activeTabIndexWizard = props.activeTabIndex + 1;
                "
              >
                {{ editarProyecto ? "Guardar & Siguiente" : "Siguiente" }}
              </el-button>

              <el-button
                v-else
                round
                class="buttonIdilia"
                :style="props.fillButtonStyle"
                @click="onComplete"
              >
                {{ props.isLastStep ? "Finalizar" : "Next" }}
              </el-button>
            </div>
          </template>
        </form-wizard>
      </el-main>
      <el-footer>
        <!-- <el-button round @click="goBack()" class="buttonIdilia" >Cancelar</el-button>
        <el-button round @click="GuardarFormulario(formProyectoRef)" class="buttonIdilia" >Guardar</el-button> -->
      </el-footer>
    </el-container>
  </div>
</template>
<script lang="ts" setup>
import { ref, reactive, defineAsyncComponent, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { ElMessageBox } from "element-plus";
import { Proyecto, useProyectosStore } from "@/store/ProyectosStore";
import { FormWizard, TabContent } from "vue3-form-wizard";
import "vue3-form-wizard/dist/style.css";
import type { UploadUserFile } from "element-plus";
import {
  ProductoAcabado,
  useProductosAcabadoStore,
} from "@/store/ProductosAcabadoStore";
import { Roles } from "@/store/CommonStore";
// import { useMaterialesStore } from '@/store/MaterialesStore'
import { useUserStore } from "@/store/UserStore";
import {
  ValidateData,
  ValidateDataItem,
  useCommonStore,
} from "@/store/CommonStore";
import { useTareasStore } from "@/store/TareasStore";
import { useMaterialesStore } from "@/store/MaterialesStore";
import { useClientesStore } from "@/store/ClientesStore";
const FormularioProyecto = defineAsyncComponent(
  () => import("../components/FormularioProyecto.vue")
);
const FormularioSeleccionPA = defineAsyncComponent(
  () => import("../components/FormularioSeleccionPA.vue")
);
const FormularioSeleccionMateriales = defineAsyncComponent(
  () => import("../components/FormularioSeleccionMateriales.vue")
);
const fullscreenLoading = ref(false);
const route = useRoute();
const validateDataProyecto = ref<ValidateData>({
  Descripcion: false,
  FechaServicio: false,
  Equipo: false,
  Subnivel: false
});
const validateDataEmpty = {
  Descripcion: false,
  ForoAprobacionPA: false,
  Marca: false,
  Familia: false,
  Subfamilia: false,
  FormatoComercial: false,
  UnidadMedidaBasicaUMB: false,
  TipoProducto: false,
  TipoVenta: false,
  CanalVenta: false,
};
const validateDataProdAcabado = ref<ValidateDataItem[]>([
  { idPA: 0, ValidateData: validateDataEmpty },
]);
const ProyectosStore = useProyectosStore();
const TareasStore = useTareasStore();
const MaterialesStore = useMaterialesStore();
// const MaterialesStore = useMaterialesStore()
const ProductosAcabadosStore = useProductosAcabadoStore();
const ClientesStore = useClientesStore();
const UserStore = useUserStore();
const CommonStore = useCommonStore();
const proyectoFoto = ref<UploadUserFile>({ name: "", url: "" });
const editarProyecto = ref(route.params.Pid === "0");
const proyectoEmpty: Proyecto = {
  IdProyecto: 0,
  Descripcion: "",
  FechaServicio: "",
  Lideres: "",
  Foto: "",
  LoteMixto: false,
  LoteMixtoComponentes: "",
  GruposAcceso: "",
  EstimacionComercial: 0,
  Clientes: "",
  Fase: "",
  Creado: "",
  CreadoPor: "",
  Equipo: "",
  Completado: false,
  Duracion: 0,
  Iniciado: false,
  KeyEquipo: "",
  TareasProximasVencer: 0,
  TareasVencidas: 0
};

let productosAcabados: ProductoAcabado[] = reactive([]);
const formProyecto: Proyecto = reactive<Proyecto>(
  route.params.Pid === "0" ? proyectoEmpty : ProyectosStore.ProyectoSelected
);
const visibleFormularioProductoAcabado = ref(false);
const visibleFormularioMateriales = ref(false);
const activeTabIndexWizard = ref(0);
const router = useRouter();
const goBack = () => {
  ProyectosStore.GetProyectos();
  ProductosAcabadosStore.GetAllProductoAcabadoEnCurso();
  router.go(-1);
};

const onComplete = async () => {
  ProyectosStore.GetProyectos();
  ProductosAcabadosStore.GetAllProductoAcabadoEnCurso();
  TareasStore.EmptyTareas();
  MaterialesStore.EmptyMaterialesPA();
  await ProyectosStore.GetProyectoById(formProyecto.IdProyecto);
  router.push({ name: "OnePage", params: { Pid: formProyecto.IdProyecto } });
};
const EditarProyectoHandle = () => {
  editarProyecto.value = true
  validateDataProyecto.value = {
    Descripcion: true,
    FechaServicio: true,
    Equipo: true,
    Subnivel: true
  }
}
const checkIsValid = (data: ValidateData, form: Proyecto | ProductoAcabado) => {
  let result = true;
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key) &&
      Object.prototype.hasOwnProperty.call(data, 'Equipo') // verificando si tiene la key equipo, se sabe que es un proyecto, asi solo se valida la descripcion del proyecto no de la PA
      && key === 'Descripcion') {
        if (ProyectosStore.Proyectos && ProyectosStore.Proyectos.some( p=> p.Descripcion.trim().toLocaleLowerCase() === form[key].trim().toLocaleLowerCase())) {
          result = false
        }
    }
    if (
      !(
        Object.prototype.hasOwnProperty.call(data, key) &&
        (data[key] || form[key])
      )
    ) {
      result = false;
    }
  }
  return result;
};
const checkDocsAttached = (form: ProductoAcabado) => {
  if (form.ForoAprobacionPA && form.ForoAprobacionPA?.indexOf("CEXT") > -1) {
    return form.Documentos && form.Documentos.length > 0;
  }
  return true;
};
const addProyectoAsync = async (
  formProyecto: Proyecto,
  image: UploadUserFile
) => {
  try {
    const result = await ProyectosStore.AddProyecto(formProyecto, image);
    if (result) {
      formProyecto.IdProyecto = result.data.IdProyecto;
      if (productosAcabados.length > 0) {
        for (
          let i = 0;
          i < productosAcabados.filter((pa) => !pa.Deleted).length;
          i++
        ) {
          productosAcabados[i].IdProyecto = result.data.IdProyecto;
          productosAcabados[i].Codigo = productosAcabados[i].EditarCodigo
            ? ""
            : productosAcabados[i].ReferenciaModelo;
          await ProductosAcabadosStore.AddProductoAcabado(productosAcabados[i]);
        }

        if (ProductosAcabadosStore.ProductosAcabados)
          productosAcabados = ProductosAcabadosStore.ProductosAcabados;

        validateDataProdAcabado.value = [];
        productosAcabados.forEach((pa) => {
          ConfigureValidateData(pa);
        });
      }
      CommonStore.CreatePlanner(result.data);
    } else {
      console.log("No se pudo añadir el proyecto");
      // resolve(false);
    }
  } catch (error: unknown) {
    console.error("Error al manejar el proyecto:", error);
    // reject(error);
  }
};

const updateProyectoAsync = async (
  formProyecto: Proyecto,
  image: UploadUserFile
) => {
  try {
    await ProyectosStore.UpdateProyecto(formProyecto, image);
    for (let i = 0; i < productosAcabados.length; i++) {
      if (productosAcabados[i].Deleted === true) {
        if (productosAcabados[i].IdPA > 0) {
          await ProductosAcabadosStore.DeleteProductoAcabado(
            productosAcabados[i].IdPA
          );
        }
        ProductosAcabadosStore.DeleteProductoAcabadoStore(productosAcabados[i]);
      } else if (productosAcabados[i].IdPA === 0) {
        productosAcabados[i].IdProyecto = formProyecto.IdProyecto;
        productosAcabados[i].Codigo = productosAcabados[i].EditarCodigo
          ? ""
          : productosAcabados[i].ReferenciaModelo;
        await ProductosAcabadosStore.AddProductoAcabado(productosAcabados[i]);
      } else {
        await ProductosAcabadosStore.UpdateProductoAcabado(
          productosAcabados[i]
        );
      }
      if (!productosAcabados[i].Deleted && formProyecto.Iniciado) {
        CommonStore.UpdatePlannerTasks(
          formProyecto,
          productosAcabados[i],
          null
        );
      } else {
        CommonStore.DeletePlannerTasks(productosAcabados[i].IdPA, "PA");
      }
    }

    if (ProductosAcabadosStore.ProductosAcabados)
      productosAcabados = ProductosAcabadosStore.ProductosAcabados;
    validateDataProdAcabado.value = [];
    productosAcabados.forEach((pa) => {
      ConfigureValidateData(pa);
    });
  } catch (error: unknown) {
    console.error("Error al manejar el proyecto:", error);
    // reject(error);
  }
};

const SaveActiveTab = () => {
  switch (activeTabIndexWizard.value) {
    case 0:
      validateAsyncProyecto(true);
      break;
    case 1:
      validateAsyncReferencias(true);
      break;
    case 2:
      onComplete();
      break;
    default:
      break;
  }
};
const validateAsyncProyecto = (changeEditProyectoButton: boolean = false) => {
  fullscreenLoading.value = true;
  return new Promise((resolve, reject) => {
    const executeAsync = async () => {
      try {
        if (!editarProyecto.value) {
          visibleFormularioProductoAcabado.value = true;
          visibleFormularioMateriales.value = false;
          fullscreenLoading.value = false;
          resolve(true);
        } else {
          if (checkIsValid(validateDataProyecto.value, formProyecto)) {
            formProyecto.Foto = proyectoFoto.value.url;
            formProyecto.FechaServicio = new Date(
              formProyecto.FechaServicio
            ).toISOString();

            if (formProyecto.IdProyecto === 0) {
              formProyecto.Creado = new Date().toISOString();
              formProyecto.CreadoPor = UserStore.CurrentUser.id;
              await addProyectoAsync(formProyecto, proyectoFoto.value);
            } else {
              await updateProyectoAsync(formProyecto, proyectoFoto.value);
              // ProyectosStore.UpdateProyecto(formProyecto, proyectoFoto.value);
            }
            if (changeEditProyectoButton) editarProyecto.value = false;
            visibleFormularioProductoAcabado.value = true;
            visibleFormularioMateriales.value = false;
            fullscreenLoading.value = false;
            resolve(true);
          } else {
            fullscreenLoading.value = false;
            await ElMessageBox.alert(
              "El formulario tiene campos requeridos que no se han informado o no son correctos. Por favor, corríjelos.",
              "Atención",
              {
                confirmButtonText: "OK",
              }
            );

            reject(
              "El formulario tiene campos requeridos que no se han informado o no son correctos. Por favor, corríjelos."
            );
          }
        }
      } catch (error: unknown) {
        console.error(error);
        fullscreenLoading.value = false;
        reject(error);
      }
    };

    executeAsync();
  });
};

const validateAsyncReferencias = (
  changeEditProyectoButton: boolean = false
) => {
  fullscreenLoading.value = true;
  return new Promise((resolve, reject) => {
    const executeAsync = async () => {
      try {
        if (editarProyecto.value) {
          await ProyectosStore.UpdateProyecto(formProyecto, proyectoFoto.value);
          const paError: string[] = [];
          if (productosAcabados.length > 0) {
          productosAcabados.forEach((pa) => {
            const validateData = validateDataProdAcabado.value.find(
              (v) => v.idPA === pa.IdPA
            );
            if (validateData && !checkIsValid(validateData.ValidateData, pa)) {
              const fieldsError =
                "<li>" +
                Object.keys(validateData.ValidateData)
                  .filter((key) => !validateData.ValidateData[key])
                  .join("</li><li>") +
                "</li>";
              paError.push(`${pa.Codigo} - ${pa.Descripcion} tiene los siguientes campos no válidos o requeridos sin rellenar: <br>
              <ul>${fieldsError}</ul><br>`);
            }
            if (!checkDocsAttached(pa)) {
              paError.push(
                `${pa.Codigo} - ${pa.Descripcion} ha de tener documentos adjuntos.`
              );
            }
          });
        } else {
          paError.push(
                `No existe ninguna PA`
              )
        }
          if (paError.length === 0) {
            productosAcabados.forEach(async (prodAcabado) => {
              if (prodAcabado.IdPA > 0) {
                await ProductosAcabadosStore.UpdateProductoAcabado(prodAcabado);
                if (formProyecto.Iniciado) {
                  CommonStore.UpdatePlannerTasks(
                    formProyecto,
                    prodAcabado,
                    null
                  );
      } 
              }
            });
            ProductosAcabadosStore.GetProductoAcabadoById(
              formProyecto.IdProyecto
            );
            if (changeEditProyectoButton) editarProyecto.value = false;
            visibleFormularioProductoAcabado.value = false;
            visibleFormularioMateriales.value = true;
            fullscreenLoading.value = false;
            resolve(true);
          } else {
            fullscreenLoading.value = false;
            await ElMessageBox.alert(paError.join("<br>"), "Atención", {
              confirmButtonText: "OK",
              dangerouslyUseHTMLString: true,
              type: "warning",
            });
            reject(
              "El formulario tiene campos requeridos que no se han informado o no son correctos. Por favor, corríjelos."
            );
          }
        } else {
          ProductosAcabadosStore.GetProductoAcabadoById(
            formProyecto.IdProyecto
          );
          visibleFormularioProductoAcabado.value = false;
          visibleFormularioMateriales.value = true;
          fullscreenLoading.value = false;
          resolve(true);
        }
      } catch (error: unknown) {
        console.error(error);
        fullscreenLoading.value = false;
        reject(error);
      }
    };
    executeAsync();
  });
};
const setValidateDataProyecto = (field: string, value: boolean) => {
  validateDataProyecto.value[field] = value;
};
const setvalidateDataProdAcabado = (
  field: string,
  value: boolean,
  idPA: number
) => {
  const index = validateDataProdAcabado.value.findIndex((v) => v.idPA === idPA);
  if (index && index > -1) {
    validateDataProdAcabado.value[index].ValidateData[field] = value;
  }
};
const updateFotoProyecto = (foto: UploadUserFile) => {
  proyectoFoto.value = foto;
};
const updateProductosAcabados = (prodAcabados: ProductoAcabado[]) => {
  productosAcabados = prodAcabados;
};
onMounted(async () => {
  if (route.params.Pid != "0") {
    await ProductosAcabadosStore.GetProductoAcabadoById(
      parseInt(route.params.Pid.toString())
    );
    validateDataProdAcabado.value = [];
    ProductosAcabadosStore.ProductosAcabados?.forEach((referencia) => {
      productosAcabados.push({ ...referencia });
      ConfigureValidateData(referencia);
    });
    proyectoFoto.value = { name: "", url: formProyecto.Foto };
  } else {
    productosAcabados = [];
    ProductosAcabadosStore.EmptyProductosAcabados();
  }
  await ClientesStore.GetClientes();
});

const ConfigureValidateData = (pa: ProductoAcabado) => {
  const validate: ValidateData = { ...validateDataEmpty };

  Object.keys(validate).forEach((v) => {
    if (pa[v]) {
      validate[v] = true;
    }
  });

  validateDataProdAcabado.value.push({ idPA: pa.IdPA, ValidateData: validate });
};
</script>
