// import { Filtro } from '@/store/CommonStore'
import { useCommonStore } from "@/store/CommonStore";
import { EstadosTareas, useTareasStore } from "@/store/TareasStore";
import moment from "moment";

export default function () {
  const dateToString = (date: string | Date | undefined) => {
    if (date) {
      return moment(date).format("DD/MM/YYYY");
    }
    return "";
  };
  const groupBy = (array: any[], key: string) => {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
    }, {});
  };
  const getDistinct = <T, K extends keyof T>(
    data: T[],
    property: K
  ): T[K][] => {
    const allValues = data.reduce((values: T[K][], current) => {
      if (current[property]) {
        values.push(current[property]);
      }
      return values;
    }, []);

    return [...new Set(allValues)];
  };

  const DaysSince = (date: string) => {
    const today = moment().startOf("day");
    const pastDate = moment(date).startOf("day");
    const differenceInDays = today.diff(pastDate, "days");
    return differenceInDays;
  };

  const GetClassByStatus = (estado: string) => {
       switch (estado) {
      case EstadosTareas.NotStarted:
        return "NoIniciado";
      case EstadosTareas.OnTime:
        return "EnCurso";
      case EstadosTareas.Delayed:
        return "Delayed";
      case EstadosTareas.Finished:
        return "Finalizado";
        case EstadosTareas.ProyectoNoIniciado:
          return "ProyectoNoIniciado";
      default:
        break;
    }
 
    return "";
  };
  const removeSpecialCharacters = (str: string) => {
    return str.replace(/[^a-zA-Z0-9 ]/g, "");
  };
  const findMissingNumber = (arr: number[]): number => {
    const n = arr.length + 1;
    const totalSum = (n * (n + 1)) / 2;
    const arraySum = arr.reduce((acc, num) => acc + num, 0);
    return totalSum - arraySum;
  };
  const generateKeyEquipos = (equipo: string, subequipo: string | null): string => {
    const sanitize = (str: string): string => str.replace(/\s+/g, '').replace(/[^\w]/g, '');
    return subequipo ? `${sanitize(equipo)}+${sanitize(subequipo)}` : sanitize(equipo);
};
const keyExistsEquipos = (key: string): boolean => {
  const CommonStore = useCommonStore()
  const TareasStore = useTareasStore()
  for (const equipo of CommonStore.Equipos) {
      if (equipo.Key === key && TareasStore.TareasConfiguracion.some(t => t.Key === key)) {
          return true;
      }
      for (const subequipo of equipo.SubEquipos) {
          if (subequipo.Key === key && TareasStore.TareasConfiguracion.some(t => t.Key === key)) {
              return true;
          }
      }
  }
  return false;
}
  return {
    dateToString,
    groupBy,
    getDistinct,
    DaysSince,
    GetClassByStatus,
    removeSpecialCharacters,
    findMissingNumber,
    generateKeyEquipos,
    keyExistsEquipos,
  };
}
